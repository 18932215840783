<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
    width="70%"
    top="7vh"
    append-to-body
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>选择试题</span>
    </div>
    <!-- seleTaskData：{{seleTaskData}} -->
    <!-- multipleSelectionAll：{{multipleSelectionAll}} -->
    <!-- <hr> -->
    <!-- multipleSelection：{{multipleSelection}} -->
    <!-- editAble：{{editAble}} -->
    <el-row :gutter="20">
      <el-col :span="8">
        <el-input placeholder="输入搜索标题、ID关键字" v-model.trim="inputVal" @keyup.enter.native="search">
          <el-select v-model="select" slot="prepend" placeholder="请选择" style="width: 120px;">
            <el-option label="试题名称" value="1"></el-option>
            <el-option label="试题id" value="2"></el-option>
          </el-select>
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </el-col>
      <el-col :span="4">
        <el-input
          class="tb-mini-input"
          placeholder="请输入创建人"
          v-model="creator"
          @keyup.enter.native="search"
        ></el-input>
      </el-col>
      <el-col :span="4">
        <el-select v-model="seleType" placeholder="请选择题目类型" style="width: 100%;">
          <el-option
            v-for="item in typeState"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="8">
        <el-row>
          <el-button type="primary" size="medium" @click="search" class="tb-button">搜 索</el-button>
          <el-button type="primary" size="medium" @click="reset" class="tb-button">重 置</el-button>
        </el-row>
      </el-col>
    </el-row>

    <el-table
      style="border-top: 1px solid #EBEEF5;border-left: 1px solid #EBEEF5;margin-top: 25px;"
      :data="tableData"
      border
      fit
      highlight-current-row
      ref="tableData"
      class="paper-table"
      @select="handleSelection"
      @selection-change="handleSelectionChange"
      @select-all="handleSelectionAll"
    >
      <el-table-column type="selection" width="55" align="center"></el-table-column>
      <el-table-column align="center" label="id" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.id | uuidFormat }}</span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="题干" width="300">
        <template slot-scope="scope">
          <span v-text="filterHTMLTag(scope.row.stem)"></span>
        </template>
      </el-table-column>

      <el-table-column align="center" label="类型" width="150">
        <template slot-scope="scope">
          <span>{{ scope.row.type }}</span>
        </template>
      </el-table-column>

      <el-table-column align="center" label="状态" width="150">
        <template slot-scope="scope">
          <span>{{ scope.row.status }}</span>
        </template>
      </el-table-column>

      <el-table-column align="center" label="创建人" width="150">
        <template slot-scope="scope">
          <span v-if="scope.row.creatorInfo !== null">{{ scope.row.creatorInfo.name }}</span>
        </template>
      </el-table-column>

      <el-table-column align="center" label="创建时间">
        <template slot-scope="scope">
          <span>{{scope.row.createTime | dateFormat}}</span>
        </template>
      </el-table-column>
    </el-table>

    <el-row>
      <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrent"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>
    </el-row>

    <!-- footer -->
    <el-row slot="footer">
      <el-button type="primary" @click="savePaper()" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { getQuestionInfo } from '@/api/research/testquestion'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    seleTaskData: {
      default: () => {
        return []
      },
      type: Array
    },
    editPaperData: {
      default: () => {
        return {}
      },
      type: Object
    },
    editAble: {
      default: false,
      type: Boolean
    }
  },
  watch: {
    seleTaskData: {
      handler (val) {
        // 转换一下目的是为了不被同步
        this.multipleSelectionAll = val
      },
      immediate: true,
      deep: true
    }
  },
  data () {
    return {
      inputVal: '', // 检索文本
      select: '1', // 检索条件
      creator: '',
      seleType: '', // 检索类型
      typeState: [
        {
          value: '单选',
          label: '单选'
        },
        {
          value: '多选',
          label: '多选'
        },
        {
          value: '简答',
          label: '简答'
        },
        {
          value: '填空',
          label: '填空'
        }
      ],
      tableData: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      multipleSelectionAll: [], // 所有选中的数据包含跨页数据
      multipleSelection: [], // 当前页选中的数据
      idKey: 'id' // 标识列表数据中每一行的唯一键的名称(需要按自己的数据改一下)
    }
  },
  methods: {
    openDialog () {
      this.pagination.currentPage = 1
      this.reset()
      this.multipleSelectionAll = []
      this.multipleSelectionAll = Object.assign([], this.seleTaskData)
      // if (this.editPaperData === null) {
      //   this.multipleSelectionAll = Object.assign([], this.seleTaskData)
      // } else {
      //   this.multipleSelectionAll = []
      // }
      this.getQuestionInfo()
      // this.changePageCoreRecordData()
    },

    // 获取试题详细信息
    async getQuestionInfo () {
      const res = await getQuestionInfo({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        title: this.select === '1' ? this.inputVal : '',
        idKey: this.select === '2' ? this.inputVal : '',
        creatorName: this.creator,
        type: this.seleType,
        statusSet: ['已发布']
      })
      this.tableData = res.body.list
      this.pagination.total = res.body.total
      setTimeout(() => {
        this.setSelectRow()
      }, 200)
    },

    // 关闭弹窗
    close () {
      this.$emit('close')
      // this.$refs.tableData.clearSelection()
    },

    handleSelection (val) {
      this.multipleSelection = val
      this.changePageCoreRecordData()
    },

    handleSelectionAll (val) {
      this.multipleSelection = val
      this.changePageCoreRecordData()
    },

    handleSelectionChange (val) {
      if (!this.editAble) {
        this.multipleSelection = val
      } else {
        this.multipleSelectionAll = val.length === 0 ? this.multipleSelectionAll : val
        if (val.length > 1) {
          this.$refs.tableData.clearSelection()
          this.$refs.tableData.toggleRowSelection(val.pop())
        }
      }
      // this.changePageCoreRecordData()
      // this.multipleSelectionAll = val
      // if (val.length > 1) {
      //   this.$refs.tableData.clearSelection()
      //   this.$refs.tableData.toggleRowSelection(val.pop())
      // }
    },

    filterHTMLTag (msg) {
      var msg = msg.replace(/<\/?[^>]*>/g, '') //去除HTML Tag
      msg = msg.replace(/[|]*\n/, '') //去除行尾空格
      msg = msg.replace(/&nbsp;/ig, '') //去掉nbsp
      return msg.substring(0, 20)
    },

    // 分页
    handleSizeChange (val) {
      if (!this.editAble) {
        this.changePageCoreRecordData()
      } else {
        this.multipleSelectionAll = []
      }
      this.pagination.pageSize = val
      this.getQuestionInfo()
    },

    handleCurrent (val) {
      // this.changePageCoreRecordData()
      if (!this.editAble) {
        this.changePageCoreRecordData()
      } else {
        this.multipleSelectionAll = []
      }
      this.pagination.currentPage = val
      this.getQuestionInfo()
    },
    // 任意位置勾选
    // handleCurrentChange (val) {
    //   this.$refs.tableData.toggleRowSelection(val)
    //   this.changePageCoreRecordData()
    // },

    // 搜索
    search () {
      this.pagination.currentPage = 1
      this.getQuestionInfo()
    },

    // 重置
    reset () {
      this.inputVal = ''
      this.select = '1'
      this.seleType = ''
      this.creator = ''
    },

    // 添加/保存试卷
    savePaper () {
      if (!this.multipleSelectionAll) {
        window.$msg('无勾选数据', 2)
      } else {
        if (!this.editAble) {
          this.$emit('saveTask', this.multipleSelectionAll)
          this.close()
        } else {
          this.$emit('editTask', this.multipleSelectionAll)
          this.close()
        }
      }
    },

    // 记忆选择核心方法
    changePageCoreRecordData () {
      // 标识当前行的唯一键的名称
      let idKey = this.idKey
      let that = this
      // 如果总记忆中还没有选择的数据，那么就直接取当前页选中的数据，不需要后面一系列计算
      if (this.multipleSelectionAll.length <= 0) {
        this.multipleSelectionAll = this.multipleSelection
        return
      }
      // 总选择里面的key集合
      let selectAllIds = []
      this.multipleSelectionAll.forEach(row => {
        selectAllIds.push(row[idKey])
      })
      let selectIds = []
      // 获取当前页选中的id
      this.multipleSelection.forEach(row => {
        selectIds.push(row[idKey])
        // 如果总选择里面不包含当前页选中的数据，那么就加入到总选择集合里
        if (selectAllIds.indexOf(row[idKey]) < 0) {
          that.multipleSelectionAll.push(row)
        }
      })
      let noSelectIds = []
      // 得到当前页没有选中的id
      this.tableData.forEach(row => {
        if (selectIds.indexOf(row[idKey]) < 0) {
          noSelectIds.push(row[idKey])
        }
      })
      noSelectIds.forEach(id => {
        if (selectAllIds.indexOf(id) >= 0) {
          for (let i = 0; i < that.multipleSelectionAll.length; i++) {
            if (that.multipleSelectionAll[i][idKey] === id) {
              // 如果总选择中有未被选中的，那么就删除这条
              that.multipleSelectionAll.splice(i, 1)
              break
            }
          }
        }
      })
    },

    // 设置选中的方法
    setSelectRow () {
      if (!this.multipleSelectionAll || this.multipleSelectionAll.length <= 0) {
        return
      }
      // 标识当前行的唯一键的名称
      let idKey = this.idKey
      let selectAllIds = []
      // this.multipleSelectionAll = Object.assign([], this.seleStuData);
      this.multipleSelectionAll.forEach(row => {
        selectAllIds.push(row[idKey])
      })
      for (var i = 0; i < this.tableData.length; i++) {
        if (selectAllIds.indexOf(this.tableData[i][idKey]) >= 0) {
          // 设置选中，记住table组件需要使用ref="table"
          this.$refs.tableData.toggleRowSelection(this.tableData[i], true)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";

// /deep/ thead {
//   .el-table-column--selection {
//     .cell {
//       display: none;
//     }
//   }
// }
</style>
