var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        top: "7vh",
        width: "70%",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", { domProps: { textContent: _vm._s(_vm.paperTitle) } }),
        ]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "试卷名称：", prop: "title" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入试卷名称" },
                model: {
                  value: _vm.formData.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "答题时长：" } },
            [
              _c("el-input-number", {
                attrs: { min: 0, label: "请输入答题时间" },
                model: {
                  value: _vm.formData.limitTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "limitTime", $$v)
                  },
                  expression: "formData.limitTime",
                },
              }),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "inline-block",
                    color: "#ccc",
                    "margin-left": "25px",
                  },
                },
                [_vm._v("分钟（0为不限时）")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "展示分数：" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "mini" },
                  model: {
                    value: _vm.formData.isShowScore,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "isShowScore", $$v)
                    },
                    expression: "formData.isShowScore",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "是" } }),
                  _c("el-radio-button", { attrs: { label: "否" } }),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "inline-block",
                    color: "#ccc",
                    "margin-left": "25px",
                  },
                },
                [_vm._v("（学生端是否展示分数）")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "发布结果：", size: "mini" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.formData.autoResult,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "autoResult", $$v)
                    },
                    expression: "formData.autoResult",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "是" } }),
                  _c("el-radio-button", { attrs: { label: "否" } }),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "inline-block",
                    color: "#ccc",
                    "margin-left": "25px",
                  },
                },
                [_vm._v("（交卷后立刻发布结果）")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "再次答题：" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "mini" },
                  model: {
                    value: _vm.formData.isRepeatable,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "isRepeatable", $$v)
                    },
                    expression: "formData.isRepeatable",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "是" } }),
                  _c("el-radio-button", { attrs: { label: "否" } }),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "inline-block",
                    color: "#ccc",
                    "margin-left": "25px",
                  },
                },
                [_vm._v("（试卷可被多次提交）")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "课程类别：", prop: "catalogId" } },
            [
              _c("el-cascader", {
                attrs: {
                  options: _vm.cascaderList,
                  props: { checkStrictly: true, value: "id", label: "title" },
                },
                model: {
                  value: _vm.formData.catalogId,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "catalogId", $$v)
                  },
                  expression: "formData.catalogId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-bottom": "10px" },
              attrs: { label: "试题列表：" },
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "mb-10",
                  attrs: {
                    type: "primary",
                    size: "small",
                    disabled: _vm.userTime !== 0,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.seleTaskPaper()
                    },
                  },
                },
                [_vm._v("选择试题")]
              ),
              _c(
                "el-table",
                {
                  ref: "dragTable",
                  attrs: {
                    data: _vm.formData.questionList,
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                    "row-key": "questionId",
                  },
                },
                [
                  _c(
                    "el-table-column",
                    { attrs: { label: "排序", align: "center", width: "60" } },
                    [_c("span", { staticClass: "el-icon-rank" })]
                  ),
                  _c("el-table-column", {
                    attrs: { label: "ID", align: "center", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("uuidFormat")(scope.row.questionId)
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "试题", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", {
                              staticClass: "title-link",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.filterHTMLTag(scope.row.question.stem)
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.editSubject(scope.row)
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "标签", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            !scope.row.question.tagList ||
                            scope.row.question.tagList.length === 0
                              ? [_c("span", [_vm._v("无")])]
                              : _vm._l(
                                  scope.row.question.tagList,
                                  function (item) {
                                    return _c(
                                      "span",
                                      { key: item.id },
                                      [
                                        Boolean(item.groupInfo)
                                          ? [
                                              _c("span", {
                                                staticClass: "tag",
                                                style:
                                                  "backgroundColor" +
                                                  ":" +
                                                  item.groupInfo.color,
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.title
                                                  ),
                                                },
                                              }),
                                            ]
                                          : [
                                              _c("span", {
                                                staticClass: "tag",
                                                style:
                                                  "backgroundColor" +
                                                  ":" +
                                                  "#ccc",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.title
                                                  ),
                                                },
                                              }),
                                            ],
                                      ],
                                      2
                                    )
                                  }
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "分值", align: "center", width: "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input", {
                              attrs: { placeholder: "请输入题目分值" },
                              on: {
                                blur: function ($event) {
                                  return _vm.onInputBlur(
                                    scope.row.score,
                                    scope.$index
                                  )
                                },
                              },
                              model: {
                                value: scope.row.score,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "score", $$v)
                                },
                                expression: "scope.row.score",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.userTime === 0
                    ? _c("el-table-column", {
                        attrs: { label: "操作", align: "center", width: "100" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", {
                                    staticClass: "el-icon-remove",
                                    staticStyle: {
                                      "font-size": "25px",
                                      cursor: "pointer",
                                      "line-height": "40px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.delQuestion(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1540687911
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.savePaper()
                },
              },
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "info" },
              on: { click: _vm.close },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
      _c("manage-topic", {
        attrs: {
          show: _vm.manageTopicDialog,
          seleTaskData: _vm.seleTaskData,
          editPaperData: _vm.editPaperData,
          editAble: _vm.editAble,
        },
        on: {
          close: function ($event) {
            _vm.manageTopicDialog = false
            _vm.editAble = false
          },
          saveTask: _vm.saveTask,
          editTask: _vm.editTask,
        },
      }),
      _c("single-choicee", {
        attrs: {
          show: _vm.singleDialog,
          ExamDialog: _vm.ExamDialog,
          editTopicData: _vm.editTopicData,
        },
        on: {
          close: function ($event) {
            _vm.singleDialog = false
            _vm.editTopicData = null
          },
          getExamInfo: _vm.getExamInfo,
        },
      }),
      _c("multiple-choicee", {
        attrs: {
          show: _vm.multipleDialog,
          ExamDialog: _vm.ExamDialog,
          editTopicData: _vm.editTopicData,
        },
        on: {
          close: function ($event) {
            _vm.multipleDialog = false
            _vm.editTopicData = null
          },
          getExamInfo: _vm.getExamInfo,
        },
      }),
      _c("essay-question", {
        attrs: {
          show: _vm.questionDialog,
          ExamDialog: _vm.ExamDialog,
          editTopicData: _vm.editTopicData,
        },
        on: {
          close: function ($event) {
            _vm.questionDialog = false
            _vm.editTopicData = null
          },
          getExamInfo: _vm.getExamInfo,
        },
      }),
      _c("filling-question", {
        attrs: {
          show: _vm.fillingDialog,
          ExamDialog: _vm.ExamDialog,
          editTopicData: _vm.editTopicData,
        },
        on: {
          close: function ($event) {
            _vm.fillingDialog = false
            _vm.editTopicData = null
          },
          getExamInfo: _vm.getExamInfo,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }